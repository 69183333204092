import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "../assets/images/menu-icon.png";
import NewsIcon from "../assets/images/news-icon.png";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import LoginModal from "../views/components/authModals/LoginModal";
import { APP_CONST } from "../config/const";
import { CustomView, isMobile } from "react-device-detect";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";
import QtechImg1 from "../assets/images/icons/sidebar/Qtech1.png";

import SpeakerIcon from "../assets/images/icons/megaphone.png";
import UserIcon from "../assets/images/icons/user.png";

import AvitorImg from "../assets/images/icons/aviator.png";
import KingMakerImg from "../assets/images/icons/king.png";
import HomeICon from "../assets/images/icons/sidebar/Home.png";
import Inplay from "../assets/images/icons/sidebar/InPlay.png";
import Cricket from "../assets/images/icons/sidebar/Cricket.png";
import Soccer from "../assets/images/icons/sidebar/Soccer.png";
import Tennis from "../assets/images/icons/sidebar/Tennis.png";
import Horse from "../assets/images/icons/sidebar/Horse.png";
import Greyhound from "../assets/images/icons/sidebar/greyhound.png";
import EvolutionIcon from "../assets/images/icons/sidebar/Evolution.png";
import LCImg from "../assets/images/icons/sidebar/LiveCasino.png";
import QtechImg from "../assets/images/icons/sidebar/Qtech.png";
import SupernowaImg from "../assets/images/icons/sidebar/Supernowa.png";
import WCImg from "../assets/images/icons/sidebar/WorldCasino.png";
import SlotsImg from "../assets/images/icons/sidebar/Slot.png";
import EvolutionImg from "../assets/images/icons/sidebar/Evolution.png";
import EzugiImg from "../assets/images/icons/sidebar/Ezugi.png";
import { handleLeftSideBar } from "../redux/app/actions";
import Sports from "../assets/images/icons/sport.png";

const Header = () => {
  const dispatch = useDispatch();
  const { showLeftSideBar } = useSelector((state) => state.app);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
    forgotpassword: false,
    resetpassword: false,
  });
  const handleShow1 = () => dispatch(handleLeftSideBar(!showLeftSideBar));

  // Date and Time Start ======>>>>>>
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  useEffect(() => {
    // Update the current date and time every second
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once
  const formattedDate = currentDateTime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = currentDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  // <<<<<<====== Date and Time End

  const headerItems = [
    {
      id: 1,
      name: "Home",
      key: "home",
      redirectionUrl: "/sports",
      image: HomeICon,
      authRequired: false,
    },
    {
      id: 2,
      name: "In-Play",
      key: "inplay",
      redirectionUrl: "/public-sports",
      image: Inplay,
      authRequired: false,
    },
    {
      id: 16,
      name: "Sports",
      key: "sports",
      redirectionUrl: "/betby",
      image: Sports,
      authRequired: false,
    },
    {
      id: 3,
      name: "Cricket",
      key: "cricket",
      redirectionUrl: "/public-sports",
      image: Cricket,
      authRequired: false,
    },
    {
      id: 4,
      name: "Soccer",
      key: "soccer",
      redirectionUrl: "/public-sports",
      image: Soccer,
      authRequired: false,
    },
    {
      id: 5,
      name: "Tennis",
      key: "Tennis",
      redirectionUrl: "/public-sports",
      image: Tennis,
      authRequired: false,
    },
    {
      id: 6,
      name: "Horse",
      key: "horse",
      redirectionUrl: "/public-sports",
      image: Horse,
      authRequired: false,
    },
    {
      id: 7,
      name: "Greyhound",
      key: "greyhound",
      redirectionUrl: "/public-sports",
      image: Greyhound,
      authRequired: false,
    },
    {
      id: 8,
      name: "Aviator",
      key: "aviator",
      redirectionUrl: "/casino/spribe/aviator",
      image: AvitorImg,
      authRequired: true,
    },
    {
      id: 16,
      name: "Kingmaker",
      key: "Kingmaker",
      redirectionUrl: "/Kingmaker",
      image: KingMakerImg,
      authRequired: true,
    },
    {
      id: 9,
      name: "Evolution",
      key: "evolution",
      redirectionUrl: "/casino/evolution",
      image: EvolutionImg,
      authRequired: true,
    },
    {
      id: 10,
      name: "Live Casino",
      key: "liveCasino",
      redirectionUrl: "/Casino",
      image: LCImg,
      authRequired: true,
    },
    {
      id: 11,
      name: "Qtech",
      key: "qtech",
      redirectionUrl: "/casino/qtech",
      image: QtechImg1,
      authRequired: true,
    },
    {
      id: 12,
      name: "Supernowa",
      key: "supernowa",
      redirectionUrl: "/casino/supernowa",
      image: SupernowaImg,
      authRequired: true,
    },
    {
      id: 13,
      name: "World Casino",
      key: "worldCasino",
      redirectionUrl: "/casino/worldcasino",
      image: WCImg,
      authRequired: true,
    },
    {
      id: 14,
      name: "Slots",
      key: "slots",
      redirectionUrl: "/Slots",
      image: SlotsImg,
      authRequired: true,
    },
    {
      id: 15,
      name: "Ezugi",
      key: "ezugi",
      redirectionUrl: "/casino/ezugi",
      image: EzugiImg,
      authRequired: true,
    },
  ];
  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);
  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  const location = useLocation();

  const checkIfActive = (key) => {
    return key === location?.state?.activePage;
  };

  return (
    <>
      {" "}
      <header className="header header_bfrlgn">
        <div className="MarqueeSection">
          <img src={SpeakerIcon} alt="speaker" />
          <marquee>
            Enjoy Unmatched Betting Excitement and Access 500+ Casino and Online
            Games
          </marquee>
          {/* <span>25 Dec 2023 11:17:10</span> */}
          <span>
            {formattedDate} {formattedTime}
          </span>
        </div>
        <div className="topHead">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    variant="primary menu-btn"
                    onClick={handleShow1}
                    className="d-block d-md-none"
                  >
                    <img src={MenuIcon} alt="User Menu Icon" width={25} />
                  </Button>

                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>

                  <div className="header-left mx-lg-auto d-none">
                    <Navbar.Toggle
                      aria-controls="basic-navbar-nav"
                      className="d-none"
                    />
                    <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                      <Nav className="me-auto">
                        {/* {<Nav.Link href="#home">New Games</Nav.Link>} */}
                        <Nav.Link
                          href="/sports"
                          // className={
                          //   activePage == "sports" || activePage === "/"
                          //     ? "active"
                          //     : ""
                          // }
                        >
                          Home
                        </Nav.Link>
                        <Nav.Link
                          href="/public-sports"
                          // className={
                          //   activePage == "public-sports" ? "active" : ""
                          // }
                        >
                          Sportsbook
                        </Nav.Link>
                        <Nav.Link
                          href="/live-dealer-public"
                          // className={
                          //   activePage == "live-dealer" ? "active" : ""
                          // }
                        >
                          Live Dealers
                        </Nav.Link>

                        <Nav.Link
                          href="/casino/vivo"
                          // className={activePage == "sign-in" ? "" : ""}
                        >
                          Aviator
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </div>

                  <div className="headerRight ms-auto ms-xl-0">
                    <ul className="d-none">
                      <li>
                        <a
                          className="tg"
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <img src={TelegramIcon} alt="Telegram Icon" />
                        </a>
                      </li>
                      <li>
                        {/* <a
                          className="wtp"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "#"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <img src={WhatsappIcon} alt="Whatsapp Icon" />
                        </a> */}
                      </li>
                    </ul>

                    <div className="theme-btn me-2 d-none">
                      <button
                        type="button"
                        className="btn"
                        onClick={toggleClass}
                      >
                        <img src={SunLight} alt="sun" className="light_theme" />
                        <img
                          src={MoonLight}
                          alt="moon"
                          className="dark_theme"
                        />
                      </button>
                    </div>

                    <Button
                      variant="primary"
                      onClick={() => {
                        isMobile
                          ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                          : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                      // onClick={() => {
                      //   navigate("/sign-in");
                      // }}
                      className="login_btn"
                    >
                      <img src={UserIcon} alt="UserIcon" />
                      LOG IN
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        isMobile
                          ? handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
                          : handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
                      }}
                      className="login_btn signup_btn"
                    >
                      <img src={UserIcon} alt="UserIcon" />
                      Sign Up
                    </Button>
                    <ul className="d-none" style={{ marginLeft: "5px" }}>
                      <li>
                        <GamesSearchModal />
                      </li>
                    </ul>
                    {/* <div className="searchbar">
                  <AiOutlineSearch />
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="announcementbar d-none">
            <span className="news">
              Announcement
              <img src={NewsIcon} alt="news" width={20} />
            </span>
            <marquee scrollamount="3">
              hfdjkshh xjlkvlkvj klsjfkls jdflkj sldkfjlk jdflkjd flkjsklfd
              jjkdflksj dflkjsdklf jlsj flksjdf
            </marquee>
          </div>
        </div>

        <LoginModal
          show={showAuthModals.login}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
          handleModalShow={handleModalShow}
        />
        {showAuthModals.mobileNumber && (
          <MobileNumberModal
            show={showAuthModals.mobileNumber}
            handleClose={() =>
              handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
            }
            handleModalShow={handleModalShow}
          />
        )}
        {showAuthModals.verifyNumber && (
          <MobileNumberVerifyModal
            show={showAuthModals.verifyNumber}
            handleClose={() =>
              handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)
            }
            handleModalShow={handleModalShow}
          />
        )}
        {showAuthModals.register && (
          <RegisterModal
            show={showAuthModals.register}
            handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
            handleModalShow={handleModalShow}
          />
        )}

        <ForgotPasswords
          show={showAuthModals.forgotpassword}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD)
          }
          handleModalShow={handleModalShow}
        />
        <ResetPassword
          show={showAuthModals.resetpassword}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.RESET_PASSWORD)
          }
          handleModalShow={handleModalShow}
        />

        {/* <Offcanvas
      className={"steps-canvas"}
      show={show}
      onHide={handleClose}
      placement={"end"}
    >
      <Offcanvas.Header closeButton="closeButton" closeVariant="white">
        <Offcanvas.Title>
          <span className="top-heading">Sign in now!</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="form-steps">
          <h2 className="h2">Hello Again!</h2>
          <p>
            Welcome back, you've
            <br />
            been missed!
          </p>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Control type="text" placeholder="Enter Username*" />
            </Form.Group>
            <Form.Group
              className="mb-3 position-relative"
              controlId="formBasicPassword"
            >
              <Form.Control type="password" placeholder="Password*" />
              <span className="icon">
                <EyeIcon />
              </span>
            </Form.Group>
            <div className="d-flex justify-content-end mb-3">
              <a className="form-link" href="#">
                Password Recovery
              </a>
            </div>
            <Button variant="primary" type="submit">
              Log in
            </Button>
          </Form>
          <div className="sign-up mt-4">
            Not a member?
            <br />
            <a href="register">Sign Up Now</a>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas> */}
        {showLeftSideBar && <MobLeftbar className="d-block d-md-none" />}
      </header>
      <div className="Bottom_head ">
        <ul>
          {Array.isArray(headerItems) &&
            headerItems.map((item, index) => {
              return (
                <li className="" key={index}>
                  <img
                    src={item?.image}
                    alt="AvitorImg"
                    className="d-md-none d-block"
                  />
                  <a
                    onClick={() => {
                      if (item?.authRequired === false) {
                        navigate(item.redirectionUrl, {
                          state: {
                            activePage: item?.key,
                          },
                        });
                      } else {
                        isMobile
                          ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                          : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }
                    }}
                    className={checkIfActive(item?.key) ? "active" : ""}
                  >
                    {item?.name}
                  </a>
                </li>
              );
            })}
          {/* <li className="d-md-block d-none">
            <img src={HomeICon} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/sports"
              className={
                activePage == "/sports" || activePage === "/" ? "active" : ""
              }
            >
              Home
            </a>
          </li>
          <li>
            <img src={Inplay} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/public-sports"
              className={activePage == "/public-sports" ? "active" : ""}
            >
              In-Play
            </a>
          </li>
          <li>
            <img src={Cricket} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/public-sports"
              className={activePage == "/public-sports" ? "active" : ""}
            >
              Cricket
            </a>
          </li>
          <li>
            <img src={Soccer} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/public-sports"
              className={activePage == "/public-sports" ? "active" : ""}
            >
              Soccer
            </a>
          </li>
          <li>
            <img src={Tennis} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/public-sports"
              className={activePage == "/public-sports" ? "active" : ""}
            >
              Tennis
            </a>
          </li>
          <li>
            <img src={Horse} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="/public-sports"
              className={activePage == "/public-sports" ? "active" : ""}
            >
              Horse
            </a>
          </li>
          <li>
            <img
              src={Greyhound}
              alt="AvitorImg"
              className="d-md-none d-block"
            />
            <a
              href="/public-sports"
              className={activePage == "/public-sports" ? "active" : ""}
            >
              Greyhound
            </a>
          </li>
          <li className="AviatorImg">
            <img src={AvitorImg} alt="AvitorImg" />
            <a
              href="#"
              onClick={() => {
                isMobile
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              Aviator
            </a>
          </li>

          <li>
            <img
              src={EvolutionImg}
              alt="AvitorImg"
              className="d-md-none d-block"
            />
            <a
              href="#"
              onClick={() => {
                isMobile
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              Evolution
            </a>
          </li>
          <li>
            <img src={LCImg} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="#"
              onClick={() => {
                isMobile
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              Live Casino
            </a>
          </li>

          <li>
            <img src={QtechImg} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="#"
              onClick={() => {
                isMobile
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              Qtech
            </a>
          </li>
          <li>
            <img
              src={SupernowaImg}
              alt="AvitorImg"
              className="d-md-none d-block"
            />
            <a
              href="#"
              onClick={() => {
                isMobile
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              Supernowa
            </a>
          </li>
          <li>
            <img src={WCImg} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="#"
              onClick={() => {
                isMobile
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              World Casino
            </a>
          </li>
          <li>
            <img src={SlotsImg} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="#"
              onClick={() => {
                isMobile
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              {" "}
              Slots
            </a>
          </li>
          <li>
            <img src={EzugiImg} alt="AvitorImg" className="d-md-none d-block" />
            <a
              href="#"
              onClick={() => {
                isMobile
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              {" "}
              Ezugi
            </a>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default Header;
